import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http : HttpClient) { }

  async getProducts():Promise<any>{
    let products
      = await this.http.get<any>(`${environment.apiBaseUrl}/api/products/products`).toPromise();
    return products;
  }

  async getSelectedProductById(id : any){
    let products 
      = await this.http.get<any>(`${environment.apiBaseUrl}/api/products/product-by-id`, {params : {id : id}}).toPromise();
      console.log(products)
    return products;
  }

  async getProductsByCatagory(catagory : string){
    let products 
      = await this.http.get<any>(`${environment.apiBaseUrl}/api/products/product-by-catagory`, {params : {catagory : catagory}}).toPromise();
    return products
  }

  async getProductsByCatagoryAndStock(catagory : string){
    let products 
      = await this.http.get<any>(`${environment.apiBaseUrl}/api/products/product-by-catagory-and-stock`, {params : {catagory : catagory}}).toPromise();
    return products
  }

  getShopProducts() : Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/api/shop/shop-products`, {observe : 'response'})
    .pipe()
  }

  getShopProductById(id : any) : Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/api/shop/shop-product-by-id`, {params : {id : id}, observe : 'response'})
    .pipe()
  }

  getGroupProducts() : Observable<any>{
    return this.http.get<any>(`${environment.apiBaseUrl}/api/group/group-products`, {observe : 'response'})
    .pipe()
  }
}
